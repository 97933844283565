import { lazy, Suspense } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom'

const LazySimu2 = lazy(() => import('./simu2/App'))
const LazySimu = lazy(() => import('./simu/App'))
const LazyAdmin = lazy(() => import('./admin/App'))
const LazyInq = lazy(() => import('./inq/App'))

const Simu2 = () => {
  return (
    <Suspense fallback={<div>...</div>}>
      <LazySimu2 />
    </Suspense>
  )
}

const Simu = () => {
  return (
    <Suspense fallback={<div></div>}>
      <LazySimu />
    </Suspense>
  )
}

const Admin = () => {
  return (
    <Suspense fallback={<div></div>}>
      <LazyAdmin />
    </Suspense>
  )
}

const Inq = () => {
  return (
    <Suspense fallback={<div></div>}>
      <LazyInq />
    </Suspense>
  )
}

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/simu2" component={Simu2} />
        <Route path="/simu" component={Simu} />
        <Route path="/admin" component={Admin}/>
        <Route path="/inq" component={Inq} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
